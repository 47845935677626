import { Plan } from "../Models/plan.model";

export enum PlanTypes {
  Monthly = "monthly",
  Quarterly = "quarterly",
  Annual = "annual",
}

export const plans: Array<Plan> = [
  {
    planType: PlanTypes.Monthly,
    price: 1590,
    discount: 0,
  },
  {
    planType: PlanTypes.Quarterly,
    price: 4500,
    discount: 5,
  },
  {
    planType: PlanTypes.Annual,
    price: 17000,
    discount: 10,
  },
];

export const planTypeOptions = [
  {
    label: "Monthly",
    value: PlanTypes.Monthly,
  },
  {
    label: "Quarterly",
    value: PlanTypes.Quarterly,
  },
  {
    label: "Annual",
    value: PlanTypes.Annual,
  },
];
